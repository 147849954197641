
import {Component, Vue, Prop} from 'vue-property-decorator';
import {Action, Mutation} from "vuex-class";
import {StoreAction, StoreMutation} from "@/types";
import {ROUTES} from "@/data";

@Component({
    name: "LogAs"
})
export default class LogAs extends Vue {
    loading = true;

    globalErrors:any = [];

    @Action('actions/logAs') logAs!: StoreAction;
    @Mutation('setSuperAdmin') setSuperAdmin!: StoreMutation;

    get token() {
        return this.$route.query?.token || '';
    }

    async init() {
        this.loading = true;
        try {
            if (!this.token) {
                this.globalErrors = {id: "noLogAsToken"};
            } else {
                // @ts-ignore;
                localStorage.setItem('auth_token', this.token);

                setTimeout(async()  => {
                    await this.setSuperAdmin(true);
                    location.href = "/";
                }, 500);
            }
        } catch (e) {
            console.log(e);
            this.globalErrors = {id: "noLogAsToken"};
            this.loading = false;
        }
    }

    beforeMount() {
        this.init();
    }
}
